import React from 'react'
import { faCircleNotch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';

import Pagination from "react-js-pagination";

// import 'react-quill/dist/quill.snow.css';



// import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

import { ADMINURL } from './MSURLS';
import { Link } from 'react-router-dom';

const Dispatcher = require('flux').Dispatcher;

var _ = require('lodash');


const { DateTime, } = require("luxon");

var ACCT = Cookies.get('ACCT')



class ItemSearch extends React.Component {
    constructor(props) {
        super()

        
        this.initSetup(props)

        this.state = {
            isLoading: false,
            isSuccess: false,
            isError: false,

            isSecondLoading: false,
            isSecondSuccess: false,
            isSecondError: false,

            searchTerm: "",

            pageNumber: 1,

            totalDocCount: 0,
            data: [],
            fullData: [],

            postData: this.postData,
            urlParams: this.urlParams,


        }

    }

    initSetup(props) {
        this.urlEndPoint = props.urlEndPoint
        this.onClickCallback = props.onClickCallback
        this.gType = props.gType
        this.searchLimit = props.searchLimit
        this.urlParams = props.urlParams
        this.apiEndpoint = props.apiEndpoint
        this.getDataCaller = props.getDataCaller

        this.data = props.data

        this.timeKey = props.timeKey
        this.updatedKey = props.updatedKey
        this.nameTitleKey = props.nameTitleKey
        this.subNameTitleKey = props.subNameTitleKey
        this.searchAllowed = props.searchAllowed
        this.hideSearch = props.hideSearch
        this.resultClickable = props.resultClickable
        this.noTime = props.noTime
        this.sortCriteria = props.sortCriteria
        this.postData = props.postData

        this.nameTitleKeySuffix = props.nameTitleKeySuffix
        this.nameTitleKeyPrefix = props.nameTitleKeyPrefix

        this.smallMessages = props.smallMessages

        this.subHeadingKeys = props.subHeadingKeys

        this.subInfoKeys = props.subInfoKeys

        this.resultView = props.resultView

        this.isResultLink = props.isResultLink
        this.resultLink = props.resultLink

        this.resultExtraClassname = ""
        this.resultStyle = {}

        this.isData = false

        this.searchTypeTimer = null

        this.linkKey = props.linkKey

        if (this.apiEndpoint === undefined || this.apiEndpoint === null) {
            this.apiEndpoint = ADMINURL
        }

        if (this.linkKey === undefined || this.linkKey === null) {
            this.linkKey = "_id"
        }

        if (this.timeKey === undefined || this.timeKey === null) {
            this.timeKey = "t"
        }
        if (this.updatedKey === undefined || this.updatedKey === null) {
            this.updatedKey = "u" // u is user????
        }

        if (this.searchAllowed === undefined || this.searchAllowed === null) {
            this.searchAllowed = true // u is user????
        }

        if (this.hideSearch === undefined || this.hideSearch === null) {
            this.hideSearch = false
        }

        if (this.isResultLink === undefined || this.isResultLink === null) {
            this.isResultLink = false
        }

        if (this.resultLink === undefined || this.resultLink === null) {
            this.resultLink = ""
        }

        if (this.noTime === undefined || this.noTime === null) {
            this.noTime = false
        } else {
            this.noTime = props.noTime
        }

        if (this.sortCriteria === undefined || this.sortCriteria === null) {
            this.sortCriteria = "t"
        }

        if (this.resultClickable !== undefined || this.resultClickable !== null) {
            if (this.resultClickable === true) {
                this.resultExtraClassname = " list-group-item-action"
                this.resultStyle = {
                    cursor: 'pointer'
                }
            }
        }


        if (this.searchLimit !== undefined || this.searchLimit !== null) {
            if (parseInt(this.searchLimit)) {
                this.searchLimit = parseInt(this.searchLimit)
            } else {
                this.searchLimit = 10
            }
        }

        if (this.smallMessages !== undefined || this.smallMessages !== null) {
            var tmpSmallMessage = []
            for (var i in this.smallMessages) {
                tmpSmallMessage.push(<small>{this.smallMessages[i]}</small>)
            }
            // tmpSmallMessage.push(<br />)
            this.smallMessages = tmpSmallMessage
        } else {
            this.smallMessages = null
        }


        /**
         * init get data dispatcher
         */
        var getDataDispatcher = new Dispatcher()
        const that = this
        getDataDispatcher.register(function (payload) {
            if (payload.actionType === "get-data") {
                that.getData()
            }
            if (payload.actionType === "set-get-data") {
                var tmpPostData = that.state.postData
                var tmpUrlParams = that.state.urlParams
                if (payload.postData !== undefined && payload.postData !== null) {
                    tmpPostData = payload.postData
                }
                if (payload.urlParams !== undefined && payload.urlParams !== null) {
                    tmpUrlParams = payload.urlParams
                }

                that.setState({
                    postData: tmpPostData,
                    urlParams: tmpUrlParams
                }, that.getData)
            }
        })

        if (this.getDataCaller !== undefined && this.getDataCaller !== null) {
            this.getDataCaller(getDataDispatcher)
        }
    }

    componentDidMount() {
        if (this.data !== undefined && this.data !== null) {
            this.isData = true
            // disable search
            this.searchAllowed = false
            this.hideSearch = true

            // assumed page number is 1
            const tmpData = this.data.slice(0, this.searchLimit)
            // this.setState({ data: this.data,totalDocCount:this.data.length })
            var dataLength = this.data.length
            // if (dataLength > this.searchLimit) {
            //     dataLength = this.searchLimit
            // }
            this.setState({ data: tmpData, fullData: this.data, totalDocCount: dataLength })

        } else {
            this.getData()
        }


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        var changed = false
        /* check if gtype is changed and apiend point is changed */
        if (prevProps.gType !== this.props.gType) {
            this.gType = this.props.gType
            changed = true
        }

        if (prevProps.apiEndpoint !== this.props.apiEndpoint) {
            this.apiEndpoint = this.props.apiEndpoint
            changed = true

        }

        if (prevProps.urlEndPoint !== this.props.urlEndPoint) {
            this.urlEndPoint = this.props.urlEndPoint
            changed = true
        }

        if (prevProps.urlEndPoint !== this.props.urlEndPoint) {
            this.urlEndPoint = this.props.urlEndPoint
            changed = true
        }

        
        if (changed) {
            this.initSetup(this.props)

            if (this.data !== undefined && this.data !== null) {
                this.isData = true
                // disable search
                this.searchAllowed = false
                this.hideSearch = true

                // assumed page number is 1
                const tmpData = this.data.slice(0, this.searchLimit)
                // this.setState({ data: this.data,totalDocCount:this.data.length })
                var dataLength = this.data.length
                // if (dataLength > this.searchLimit) {
                //     dataLength = this.searchLimit
                // }
                this.setState({ data: tmpData, fullData: this.data, totalDocCount: dataLength })

            } else {
                this.getData()
            }
        }


    }

    handleGetData = (response) => {
        if (!("status" in response) || !("payload" in response)) {

            if (!this.state.isSuccess) {
                this.setState({ isLoading: false, isError: true, isSuccess: false, totalDocCount: 0, data: [] })
            } else {
                this.setState({
                    isSecondLoading: false,
                    isSecondSuccess: false,
                    isSecondError: true,
                    totalDocCount: 0,
                    data: []
                })
            }
            return
        }

        if (response["status"] !== "success") {
            if (!this.state.isSuccess) {
                this.setState({ isLoading: false, isError: true, isSuccess: false, totalDocCount: 0, data: [] })
            } else {
                this.setState({
                    isSecondLoading: false,
                    isSecondSuccess: false,
                    isSecondError: true,
                    totalDocCount: 0,
                    data: []
                })
            }
            return
        }

        if (!this.state.isSuccess) {
            this.setState({ isLoading: false, isSuccess: true, isError: false })
        } else {
            this.setState({
                isSecondLoading: false,
                isSecondSuccess: true,
                isSecondError: false,
            })
        }


        this.setState({ data: response.payload.data, totalDocCount: response.payload.tc })
    }

    getData = () => {
        var ACCT = Cookies.get('ACCT')

        if (this.isData === true) {
            var tmpData = []
            tmpData = this.data.slice((this.state.pageNumber - 1) * this.searchLimit, this.state.pageNumber * this.searchLimit)

            if (tmpData.length === 0) {
                if (this.state.pageNumber >= 2) {
                    tmpData = this.data.slice((this.state.pageNumber - 2) * this.searchLimit, this.state.pageNumber * this.searchLimit)
                    this.setState({ pageNumber: this.state.pageNumber - 1, data: tmpData, totalDocCount: this.data.length }, () => {

                    })
                }
            } else {
                this.setState({ data: tmpData, totalDocCount: this.data.length }, () => {

                })
            }


            return;
        }

        if (!this.state.isSuccess) {
            this.setState({ isLoading: true })
        } else {
            this.setState({
                isSecondLoading: true,
                isSecondSuccess: false,
                isSecondError: false,
            })
        }
        const that = this
        ACCT = Cookies.get('ACCT')
        axios.post(this.apiEndpoint + this.urlEndPoint, {
            ...this.state.postData
        }, {
            params: {
                g: this.gType,
                pn: this.state.pageNumber,
                sc: this.sortCriteria,
                st: this.state.searchTerm,
                sl: this.searchLimit,
                ...this.state.urlParams
            },
            headers: { Authorization: ACCT },

        })
            .then(function (response_) {
                const response = response_.data
                that.handleGetData(response)
            })
            .catch(function (error) {
                console.log(error)
                that.handleGetData({})
            })
    }

    render() {

        if (this.state.isError) {
            return (
                <div>
                    <span className="text-danger">
                        Unable to fetch search results.
                    </span>
                </div>
            )
        }
        if (this.state.isLoading) {
            return (
                <div className='d-flex d-flex justify-content-center' >
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
            )
        }

        var data = []
        for (const i in this.state.data) {
            const idx = i

            var timeDiv = null
            if (!this.noTime && this.timeKey in this.state.data[i]) {
                // timeDiv = <small title={DateTime.fromMillis(this.state.data[i][this.timeKey].$date).toRelative().toString()}>{DateTime.fromMillis(this.state.data[i][this.timeKey].$date).toLocaleString(DateTime.DATETIME_MED)}</small>
                timeDiv = <small title={DateTime.fromISO(this.state.data[idx][this.timeKey]).toRelative().toString()}>{DateTime.fromISO(this.state.data[idx][this.timeKey]).toLocaleString(DateTime.DATETIME_MED)}</small>
            }

            // subheadings 

            var subheadings = []
            if (this.subHeadingKeys !== undefined && this.subHeadingKeys !== null && this.subHeadingKeys.length > 0) {
                for (var shk in this.subHeadingKeys) {
                    // alert(_.get(this.state.data[i], this.subHeadingKeys[shk]))
                    var val = _.get(this.state.data[idx], this.subHeadingKeys[shk])
                    if (val === undefined || val === null) {

                    } else {
                        subheadings.push([
                            <small>{_.get(this.state.data[idx], this.subHeadingKeys[shk])}</small>,
                            <br />,
                        ])
                    }

                }
            }

            var subinfo = []
            if (this.subInfoKeys !== undefined && this.subInfoKeys !== null && this.subInfoKeys.length > 0) {
                for (var si in this.subInfoKeys) {

                    var result = this.subInfoKeys[si].map(_.get(this.state.data[idx], this.subInfoKeys[si].key))

                    if (result !== null && result !== undefined) {
                        subinfo.push([
                            <small>{result}</small>,
                            // <br />,
                        ])
                    }
                }
            }

            if (this.isResultLink === false) {
                if (this.subNameTitleKey !== undefined && this.subNameTitleKey !== null && this.subNameTitleKey.length > 0) {
                    data.push(
                        <li key={`li1-${this.state.pageNumber}-${idx}`} data-iidx={idx} className={"list-group-item flex-column align-items-start " + this.resultExtraClassname} style={this.resultStyle} onClick={(e) => {
                            /* get parent li */
                            var parent_li = e.target.closest('li')
                            const ii = parseInt(parent_li.dataset.iidx)
                            /* */
                            this.onClickCallback(this.state.data[ii], ii, this.state.pageNumber)
                        }}>
                            <div className="d-flex w-100 justify-content-between" style={{

                            }}>
                                <span>
                                    <h5 className="mb-1">{this.nameTitleKeyPrefix}{_.get(this.state.data[idx], this.nameTitleKey)}{this.nameTitleKeySuffix}</h5>
                                    <p className="pb-0 mb-0" style={{
                                        whiteSpace: "pre-wrap"
                                    }}>
                                        {_.get(this.state.data[idx], this.subNameTitleKey)}
                                    </p>
                                    {/* {this.state.data[i][this.subNameTitleKey]} */}
                                    {subheadings}
                                    {subinfo}
                                </span>
                                {/* <small title={DateTime.fromISO(this.state.ledger[i].t).toLocaleString(DateTime.DATETIME_MED)}>{DateTime.fromISO(this.state.ledger[i].t).toRelative().toString()}</small> */}
                                {timeDiv}
                            </div>
                        </li>
                    )
                } else {

                    data.push(
                        <li key={`li2-${this.state.pageNumber}-${idx}`} data-iidx={idx} className={"list-group-item flex-column align-items-start " + this.resultExtraClassname} style={this.resultStyle} onClick={(e) => {
                            /* get parent li */
                            var parent_li = e.target.closest('li')
                            const ii = parseInt(parent_li.dataset.iidx)
                            /* */
                            this.onClickCallback(this.state.data[ii], ii, this.state.pageNumber)
                        }
                        }>
                            <div style={{

                            }} className="d-flex w-100 justify-content-between">
                                <span>
                                    <h5 className="mb-1">{this.nameTitleKeyPrefix}{_.get(this.state.data[idx], this.nameTitleKey)}{this.nameTitleKeySuffix}</h5>
                                    {subinfo}
                                </span>
                                {/* <small title={DateTime.fromISO(this.state.ledger[i].t).toLocaleString(DateTime.DATETIME_MED)}>{DateTime.fromISO(this.state.ledger[i].t).toRelative().toString()}</small> */}
                                {timeDiv}
                            </div>
                        </li>
                    )
                }
            } else {
                if (this.subNameTitleKey !== undefined && this.subNameTitleKey !== null && this.subNameTitleKey.length > 0) {
                    data.push(
                        <Link key={`li3-${this.state.pageNumber}-${idx}`} to={this.resultLink + this.state.data[idx][this.linkKey]} className={"list-group-item flex-column align-items-start " + this.resultExtraClassname} style={this.resultStyle} >
                            <div className="d-flex w-100 justify-content-between">
                                <span>
                                    <h5 className="mb-1">{this.nameTitleKeyPrefix}{_.get(this.state.data[idx], this.nameTitleKey)}{this.nameTitleKeySuffix}</h5>
                                    <p className="pb-0 mb-0" style={{
                                        whiteSpace: "pre-wrap"
                                    }}>
                                        {_.get(this.state.data[idx], this.subNameTitleKey)}
                                    </p>
                                    {/* {this.state.data[i][this.subNameTitleKey]} */}
                                    {subheadings}
                                    {subinfo}
                                </span>
                                {/* <small title={DateTime.fromISO(this.state.ledger[i].t).toLocaleString(DateTime.DATETIME_MED)}>{DateTime.fromISO(this.state.ledger[i].t).toRelative().toString()}</small> */}
                                {timeDiv}
                            </div>
                        </Link>
                    )
                } else {
                    data.push(
                        <Link key={`li4-${this.state.pageNumber}-${idx}`} to={this.resultLink + this.state.data[idx][this.linkKey]} className={"list-group-item flex-column align-items-start " + this.resultExtraClassname} style={this.resultStyle} >
                            <div className="d-flex w-100 justify-content-between">
                                <span>
                                    <h5 className="mb-1">{this.nameTitleKeyPrefix}{_.get(this.state.data[idx], this.nameTitleKey)}{this.nameTitleKeySuffix}</h5>
                                    {subinfo}
                                </span>
                                {/* <small title={DateTime.fromISO(this.state.ledger[i].t).toLocaleString(DateTime.DATETIME_MED)}>{DateTime.fromISO(this.state.ledger[i].t).toRelative().toString()}</small> */}
                                {timeDiv}
                            </div>
                        </Link>
                    )
                }
            }

        }

        // set search stuff

        return (
            <div className="m-2" style={{
                position: 'relative'
            }}>
                {
                    this.state.isSecondLoading ? <>
                        <div style={{
                            background: `rgba(0,0,0,0.1)`,
                            position: 'absolute',
                            top: '0',
                            bottom: '0',
                            right: '0',
                            left: '0',
                            zIndex: 1000
                        }}>

                        </div>
                    </> : <></>
                }
                <div key="itemSearchSearchBar" className="d-flex flex-row align-items-center">
                    <input hidden={this.hideSearch} key="itemSearchSearchBar" className="form-control" value={this.state.searchTerm} disabled={!this.searchAllowed} placeholder="Search" onChange={(e) => {
                        this.setState({ searchTerm: e.target.value }, () => {

                            if (this.searchTypeTimer === null) {
                                this.searchTypeTimer = window.setTimeout(() => {
                                    this.setState({ pageNumber: 1 }, () => {
                                        this.getData()
                                    })
                                }, 500)
                            } else {
                                clearTimeout(this.searchTypeTimer)
                                this.searchTypeTimer = window.setTimeout(() => {
                                    this.setState({ pageNumber: 1 }, () => {
                                        this.getData()
                                    })
                                }, 500)
                            }
                        })
                    }} />
                    <span hidden={this.state.searchTerm === ""}>
                        <FontAwesomeIcon icon={faTimesCircle} className="ml-2" style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            this.setState({ searchTerm: "" }, this.getData)
                        }} />
                    </span>
                </div>

                <div className="d-inline-flex justify-content-center col-sm-12 col-xl-12 col-lg-12 p-2">
                    <div className="d-inline-flex  justify-content-center align-items-center w-100">
                        <Pagination
                            innerClass="pagination"
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={this.state.pageNumber}
                            itemsCountPerPage={this.searchLimit}
                            totalItemsCount={this.state.totalDocCount}
                            pageRangeDisplayed={5}
                            onChange={(pageNumber) => {
                                this.setState({ pageNumber: parseInt(pageNumber) }, this.getData)
                            }}
                        />
                    </div>
                </div>
                <div className="m-2">
                    {this.smallMessages}
                    <small>Total Records: {this.state.totalDocCount}. Showing: {((this.state.pageNumber - 1) * this.searchLimit) + 1} - {((this.state.pageNumber - 1) * this.searchLimit) + data.length}.</small>&nbsp;
                </div>
                <ul class="list-group">
                    {data}
                </ul>
            </div>
        )
    }
}


export {
    ItemSearch
}