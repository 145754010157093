import React from 'react'
import Cookies from "js-cookie";
import { fullDialogV2Dispatcher } from './FullDialogV2';

export function ecommerce_helper_function_get_formatted_price(price, multiplier_, currency_, denominator_, upto_, hide_decimal_) {
    var currency = <>&#8377;</>
    if (currency_) {
        currency = currency_
    }

    var denominator = 100
    if (denominator_) {
        denominator = denominator_
    }

    var upto = 2
    if (upto_) {
        upto = upto_
    }

    var hide_decimal = true
    if (hide_decimal_) {
        hide_decimal = hide_decimal_
    }

    var multiplier = 1
    if (multiplier_) {
        multiplier = multiplier_
    }

    if (hide_decimal) {
        return <>{currency} {parseFloat((parseInt(price) * parseInt(multiplier)) / denominator).toFixed(upto).replace(/[.,]00$/, "")}</>
    }
    return <>{currency} {parseFloat((parseInt(price) * parseInt(multiplier)) / denominator).toFixed(upto)}</>
}

export function ecommerce_helper_function_get_cart_total(cart_items, returnFormatted, multiplier_, currency_, denominator_, upto_, hide_decimal_) {

    var total = 0

    for (var i in cart_items) {
        // console.log(i)
        total += parseInt(cart_items[i].item.price.sp) * parseInt(cart_items[i].cartQuantity)
    }
    if (returnFormatted) {
        return ecommerce_helper_function_get_formatted_price(total, multiplier_, currency_, denominator_, upto_, hide_decimal_)
    }
    return total
}





export function ecommerce_helper_function_get_coupon_discount_value(couponData, cartValue, shippingValue, returnFormatted, multiplier_, currency_, denominator_, upto_, hide_decimal_) {
    const couponAmount = couponData.a

    const couponType = couponData.ct // not that important in value calculation
    const discountType = couponData.dt

    const uptoAmount = couponData.ua

    const isForShipping = couponData.s
    const allShippingOff = couponData.as

    var discountAmount = 0

    if (isForShipping && allShippingOff) {
        discountAmount = parseInt(shippingValue)
    } else if (isForShipping && !allShippingOff) {
        if (discountType === "%") {
            discountAmount = parseInt(shippingValue * (couponAmount / 100))
        } else if (discountType === "d") {
            discountAmount = parseInt(couponAmount)
        }
        if (discountAmount > uptoAmount) {
            discountAmount = uptoAmount
        }
    } else {
        if (discountType === "%") {
            discountAmount = parseInt(cartValue * (couponAmount / 100))
        } else if (discountType === "d") {
            discountAmount = parseInt(couponAmount)
        }
        if (discountAmount > uptoAmount) {
            discountAmount = uptoAmount
        }
    }
    if (returnFormatted) {
        return ecommerce_helper_function_get_formatted_price(discountAmount, multiplier_, currency_, denominator_, upto_, hide_decimal_)
    }
    return discountAmount

}


export function ecommerce_helper_function_get_wallet_amount(walletValue, cartItems, shippingAmount, couponAmount, returnFormatted, currency_, denominator_, upto_, hide_decimal_) {

    var walletAmount = 0


    if (ecommerce_helper_function_get_cart_total(cartItems) + shippingAmount - couponAmount < walletValue) {
        walletAmount = ecommerce_helper_function_get_cart_total(cartItems) + shippingAmount - couponAmount
    } else {
        walletAmount = walletValue
    }



    if (returnFormatted) {
        return ecommerce_helper_function_get_formatted_price(walletAmount, 1, currency_, denominator_, upto_, hide_decimal_)
    }
    return walletAmount
}



export function ecommerce_helper_function_get_cart_item_number(cartItems, countPieces) {

    var totalItems = 0

    for (var i in cartItems) {
        var multiplier = 1
        if (countPieces) {
            multiplier = cartItems[i].cartQuantity
        }

        totalItems += 1 * multiplier
    }



    return totalItems
}


export function helper_function_yes_no_dialog(title, prompt, yesTitle, noTitle, onYes, onNo, closeOnYes, isYesNeg) {

	var dialogIndex = null
	if (isYesNeg === undefined || isYesNeg === null) {
		isYesNeg = false
	}
	fullDialogV2Dispatcher.dispatch({
		actionType: "show-dialog",
		title: title,
		dialogIndexCallback: (d) => {
			dialogIndex = d
		},
		message: <div>
			<div className="form-group">
				{prompt}
			</div>
			<div className="form-group d-flex justify-content-start" style={{
				gap: '1ch'
			}}>
				<button className={`btn btn-${isYesNeg ? 'danger' : 'primary'}`} onClick={() => {

					if (closeOnYes) {
						fullDialogV2Dispatcher.dispatch({
							actionType: "close-dialog",
							dialogIndex: dialogIndex
						})
					}
					if (onYes) {
						onYes()
					}
				}}>
					{yesTitle}
				</button>
				<button className={`btn btn-${!isYesNeg ? 'danger' : 'primary'}`} onClick={() => {

					fullDialogV2Dispatcher.dispatch({
						actionType: "close-dialog",
						dialogIndex: dialogIndex
					})

					if (onNo) {
						onNo()
					}
				}} >
					{noTitle}
				</button>
			</div>
		</div>
	})

}


export function helper_function_info_dialog(title, prompt, showCloseButton, closeButtonTitle, onClose) {
	var dialogIndex = null

	fullDialogV2Dispatcher.dispatch({
		actionType: "show-dialog",
		title: title,
		dialogIndexCallback: (d) => {
			dialogIndex = d
		},
		message: <div>
			<div className="form-group">
				{prompt}
			</div>
			{
				showCloseButton ?
					<div className="form-group d-flex justify-content-start" style={{
						gap: '1ch'
					}}>
						<button className="btn btn-primary" onClick={() => {

							fullDialogV2Dispatcher.dispatch({
								actionType: "close-dialog",
								dialogIndex: dialogIndex
							})

							if (onClose) {
								onClose()
							}
						}}>
							{closeButtonTitle}
						</button>
					</div>

					: null
			}

		</div>
	})


}


export function helper_function_validat_email(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

