const main_config = {
    "companyInfo": {
        "name": "Joypur Wala",
        "address1": "First Floor, Naila House Road",
        "address2": "Opp. Carewell Hospital, Bees Dukan",
        "city": "Jaipur",
        "state": "Rajasthan",
        "country": "India",
        "pincode": "302004",
        "gst": "08AZTPK3085B1Z6",
        "pan": "",
        "phone": "+91-9001111122",
        "orderNotificationEmail": "onlinejoypurwala@gmail.com",
        "fromEmail": "onlinejoypurwala@gmail.com",
        "domain": "joypurwala.com",
        "websiteAddress": "https://joypurwala.com/",
        "logoImageAddress": ""
    },
    "url-shortner": {
        "prefix-letter": "m"
    },
    "adminurl": " https://846cd69b01e9f66a.joypurwala.com/api/ecom/",
    "msurl": " https://846cd69b01e9f66a.joypurwala.com/api/ecom/"
}



// const main_config = {
//     "companyInfo": {
//         "name": "C3RL",
//         "address1": "130, Swaroop Vihar",
//         "address2": "Jagatpura",
//         "city": "Jaipur",
//         "state": "Rajasthan",
//         "country": "India",
//         "pincode": "302017",
//         "gst": "08AAJCC3178M1Z5",
//         "pan": "",
//         "phone": "+91-9929651778",
//         "orderNotificationEmail": "info@c3rl.com",
//         "fromEmail": "info@c3rl.com",
//         "domain": "c3rl.com",
//         "websiteAddress": "https://c3rl.com/",
//         "logoImageAddress": ""
//     },
//     "url-shortner": {
//         "prefix-letter": "e"
//     },
//     "adminurl": "https://ecom-admin-54d15320d5cdca9a6d6e.c3rl.com/api/ecom/",
//     "msurl": "https://ecom-admin-54d15320d5cdca9a6d6e.c3rl.com/api/ecom/"
// }



// const main_config = {
//     "companyInfo": {
//         "name": "C3RL",
//         "address1": "130, Swaroop Vihar",
//         "address2": "Jagatpura",
//         "city": "Jaipur",
//         "state": "Rajasthan",
//         "country": "India",
//         "pincode": "302017",
//         "gst": "08AAJCC3178M1Z5",
//         "pan": "",
//         "phone": "+91-9929651778",
//         "orderNotificationEmail": "info@c3rl.com",
//         "fromEmail": "info@c3rl.com",
//         "domain": "c3rl.com",
//         "websiteAddress": "https://c3rl.com/",
//         "logoImageAddress": ""
//     },
//     "url-shortner": {
//         "prefix-letter": "e"
//     },
//     "adminurl": "http://127.0.0.1:8082/api/ecom/",
//     "msurl": "http://127.0.0.1:8082/api/ecom/"
// }



// const main_config = {
//     "companyInfo": {
//         "name": "Kasturi Opulence",
//         "address1": "Flat S-1, Plot No. 130",
//         "address2": "Swaroop Vihar, Jagatpura",
//         "city": "Jaipur",
//         "state": "Rajasthan",
//         "country": "India",
//         "pincode": "302017",
//         "gst": "08CUIPS3176B1ZH",
//         "pan": "",
//         "phone": "+91-9610895337",
//         "orderNotificationEmail": "beenasaxena28@gmail.com",
//         "fromEmail": "contact@kasturiopulence.com",
//         "domain": "kasturiopulence.com",
//         "websiteAddress": "https://kasturiopulence.com/",
//         "logoImageAddress": ""
//     },
//     "url-shortner": {
//         "prefix-letter": "k"
//     },
//     "adminurl": "https://ecom-admin-12bffb236c34da8e8793.kasturiopulence.com/api/ecom/",
//     "msurl": "https://ecom-admin-12bffb236c34da8e8793.kasturiopulence.com/api/ecom/"
// }

export default main_config