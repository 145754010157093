import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './App.css'
import { Home } from './Pages/Home';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import { Pages } from './Pages/Pages';
import Login from './Pages/Login';
import { Front } from './Pages/Front';
import { Orders, SingleOrder } from './Pages/Orders';
import { Footer } from './Pages/Footer';
import { Header } from './Pages/Header';
import { Collections, SingleCollection } from './Pages/Collections';
import { AssignDescriptionWindowed, Descriptions, EditDescriptionWindowed, ApproveDescriptionWindowed } from './Pages/Descriptions';
import axios from 'axios';

import Cookies from 'js-cookie';
import Logout from './Pages/Logout';
import { Marketing, SingleEmailCampaign } from './Pages/Marketing';
import { URLShortener } from './Pages/UrlShortener';
import { Wallet } from './Pages/Wallet';
import { Coupons, SingleCoupon } from './Pages/Coupons';
import { Inventory } from './Pages/Inventory';
import { Subscribers } from './Pages/Subscribers';
import { Comments, SingleComment } from './Pages/Comments';
import { Reviews, SingleReview } from './Pages/Reviews';
import { Notify } from './Pages/Notify';
import { SingleManualUser, SingleUser, Users } from './Pages/Users';
import { ADMINURL } from './Components/MSURLS';
import { Shipping } from './Pages/shipping';
import { Storage } from './Pages/Storage';
import { Admin } from './Pages/Admin';

var ACCT = Cookies.get('ACCT')

const routing = (
  <Router>
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/pages">
        <Pages />
      </Route>
      <Route path="/front">
        <Front />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>
      <Route path="/footer">
        <Footer />
      </Route>
      <Route path="/header">
        <Header />
      </Route>
      <Route path="/collections/:id">
        <SingleCollection />
      </Route>
      <Route path="/collections">
        <Collections />
      </Route>
      <Route exact path="/descriptions/assign">
        <AssignDescriptionWindowed />
      </Route>
      <Route exact path="/descriptions/approve">
        <ApproveDescriptionWindowed />
      </Route>
      <Route path="/descriptions/:id">
        <EditDescriptionWindowed />
      </Route>
      <Route path="/descriptions">
        <Descriptions />
      </Route>
      <Route path="/orders/:id">
        <SingleOrder />
      </Route>
      <Route path="/orders" render={() => (
        <Orders />
      )}>
      </Route>
      <Route path="/marketing/email/:id">
        <SingleEmailCampaign />
      </Route>
      <Route path="/marketing">
        <Marketing />
      </Route>
      <Route path="/urlshortener">
        <URLShortener />
      </Route>
      <Route path="/wallet">
        <Wallet />
      </Route>
      <Route path="/coupons/:id">
        <SingleCoupon />
      </Route>
      <Route path="/coupons">
        <Coupons />
      </Route>
      <Route path="/inventory">
        <Inventory />
      </Route>
      <Route path="/subscribers">
        <Subscribers />
      </Route>
      <Route path="/reviews/:id">
        <SingleReview />
      </Route>
      <Route path="/reviews">
        <Reviews />
      </Route>
      <Route path="/comments/:id">
        <SingleComment />
      </Route>
      <Route path="/comments">
        <Comments />
      </Route>
      <Route path="/notify">
        <Notify />
      </Route>
      <Route path="/users/:id">
        <SingleUser />
      </Route>
      <Route path="/users">
        <Users />
      </Route>
      <Route path="/shipping">
        <Shipping />
      </Route>
      <Route path="/manualusers/:id">
        <SingleManualUser />
      </Route>

      <Route path="/storage">
        <Storage />
      </Route>

      <Route path="/admin">
        <Admin />
      </Route>
      
      <Route>
        <Home />
      </Route>
    </Switch>
  </Router>
)

ReactDOM.render(
  <React.StrictMode>
    <div className="p-2">
      Loading...
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);


// check session if success 
function handleSession(response) {
  // this.setState({ isLoading: false })

  if (!("status" in response) || !("payload" in response)) {
    alert("Unable to verify app session. Please try again later.")
    ReactDOM.render(
      <React.StrictMode>
        <div className="p-2">
          Unable to verify app session. Please try again later.
        </div>
      </React.StrictMode>,
      document.getElementById('root')
    );
    return
  }
  if (response["status"] !== "success") {
    ReactDOM.render(
      <React.StrictMode>
        <Login />
      </React.StrictMode>,
      document.getElementById('root')
    );
    return
  }

  ReactDOM.render(
    <React.StrictMode>
      {routing}
    </React.StrictMode>,
    document.getElementById('root')
  );
}

axios.get(ADMINURL + "echo", {
  headers: { Authorization: ACCT },
})
  .then(function (response) {
    handleSession(response.data)
  })
  .catch(function (error) {
    console.log(error)
    handleSession({})
  })


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
